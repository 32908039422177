import { Disclosures } from 'models/enums';

export const IGNORE_COMMENT_FIELDS = [Disclosures.ConstructedBefore1978];

export const DISCLOSURES_LIST = [
  { name: Disclosures.ConstructedBefore1978, title: 'My home was constructed before <span>January 1, 1978.</span>' },
  { name: Disclosures.HasSepticSystem, title: 'My home has a septic system.' },
  { name: Disclosures.HasPublicSewage, title: 'My home has a public sewage.' },
  {
    name: Disclosures.LeadPaint,
    title: 'I have knowledge of lead-based paint or lead-based <span>paint hazards.</span>',
  },
  {
    name: Disclosures.LeadPaintTreatment,
    title: 'I have knowledge of any lead-based paint-related treatment.',
  },
  {
    name: Disclosures.LeadPaintReports,
    title: 'I have reports or records pertaining to lead-based paint or lead-based <span>paint hazards.</span>',
  },
  { name: Disclosures.Mold, title: 'I have knowledge <span>of mold.</span>' },
  { name: Disclosures.MoldTreatment, title: 'I have knowledge of any mold-related treatment.' },
  {
    name: Disclosures.MoldReports,
    title: ' I have reports or records pertaining to mold or <span>mold hazards.</span>',
  },
  { name: Disclosures.Asbestos, title: 'I have knowledge <span>of asbestos.</span>' },
  { name: Disclosures.AsbestosTreatment, title: 'I have knowledge of any asbestos-related treatment.' },
  {
    name: Disclosures.AsbestosReports,
    title: 'I have reports or records pertaining to asbestos or <span>asbestos hazards.</span>',
  },

  { name: Disclosures.Death, title: 'I have knowledge of a death on premises in last <span>3 years.</span>' },
  { name: Disclosures.SharedGasMeters, title: 'This home shares a gas meter <span>with others.</span>' },
  { name: Disclosures.SharedElectricMeters, title: 'This home shares an electric meter <span>with others.</span>' },
  { name: Disclosures.SharedWaterMeters, title: 'This home shares a water meter <span>with others.</span>' },
  {
    name: Disclosures.MilitaryOrdnanceNearby,
    title: 'I have knowledge of a former federal or state ordnance location within one mile of <span>the home.</span>',
  },
  {
    name: Disclosures.IntentionToDemolishHome,
    title: 'I have applied for a permit to demolish <span>the home.</span>',
  },
  {
    name: Disclosures.NoWaterConservingPlumbing,
    title: 'I have not installed water-conserving plumbing fixtures (toilets, <span>showerheads, etc.).</span>',
  },
  {
    name: Disclosures.IsLocatedNearFloodZone,
    title:
      'I have received notice from a public agency that the property is located in a special flood hazard zone or an area of potential flooding.',
  },
  {
    name: Disclosures.MortgageRequiresFloodInsurance,
    title: 'My mortgage holder requires me to carry <span>flood insurance</span>.',
  },
  { name: Disclosures.HasFloodInsurance, title: 'I currently carry flood insurance for this home.' },
  { name: Disclosures.OtherMaterialFacts, title: 'There are other material facts I <span>should disclose.</span>' },
  { name: Disclosures.IsIn100YearFloodPlain, title: 'My home sits on a 100 year floodplain.' },
  { name: Disclosures.FloodedInLast5Years, title: 'My home was flooded in the last 5 years.' },
  {
    name: Disclosures.FloodedAtLeastThreeTimesInLast5Years,
    title:
      'The living space or attached area has experienced flooding three or more times in the past\u00a0five\u00a0years',
  },
  {
    name: Disclosures.RadonDisclosureTheHomeownerHasKnowledge,
    title: 'I have knowledge of radon or radon-related information about my home.',
  },
  {
    name: Disclosures.RadonDisclosureTheHomeownerHasDocuments,
    title: 'I have radon reports.',
    // Enable when we allow uploading files
    filesAllowed: false,
    filesRequired: false,
  },
  {
    name: Disclosures.RadonDisclosureTheHomeownerHasKnowledgeOfConcentration,
    title: 'I have knowledge of my home’s radon concentration.',
  },
  {
    name: Disclosures.HasKnowledgeOfRadonAtOrAboveTheRecommended,
    title:
      'Radon concentrations (at or above the IEMA recommended Radon Action Level of 4.0 pCi/L) are known to be present within the dwelling unit',
  },
  {
    name: Disclosures.HasRadonRecordsOrReports,
    title: 'I have radon records or reports pertaining to radon concentrations within the dwelling unit.',
  },
];
