import { DISCLOSURES_LIST } from 'accounts/constants/home-disclosures';
import { HOME_DISCLOSURES_STRINGS } from 'accounts/constants/strings/home-disclosures.strings';
import Field from 'components/Field/Field';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { MEDIA_LABEL, NewMediaUploadAdapter, validateMedia } from 'components/NewMediaUploader/NewMediaUploader';
import { YesNoFinalFormAdapter } from 'components/Selector/YesNoFinalFormAdapter/YesNoFinalFormAdapter';
import { Col } from 'forkedlibraries/react-bootstrap';
import { Disclosures } from 'models/enums';
import { composeValidators, integer, required } from 'utils/validation';

export function DisclosureFormField({ item }: { item: typeof DISCLOSURES_LIST[number] }) {
  const showHasSepticSystem = item.name === Disclosures.HasSepticSystem;

  return (
    <Col md={24}>
      {showHasSepticSystem && (
        <Field
          validate={composeValidators(required, integer)}
          placeholder={HOME_DISCLOSURES_STRINGS.permitted_bedrooms}
          component={InputFinalFormAdapter}
          name={`disclosures.types.${item.name}.bedroomsCountPermitted`}
        />
      )}

      {item.name === Disclosures.RadonDisclosureTheHomeownerHasKnowledgeOfConcentration && (
        <Field
          validate={required}
          component={YesNoFinalFormAdapter}
          alignCenter
          label={HOME_DISCLOSURES_STRINGS.known_radon_concentration}
          name={`disclosures.types.${item.name}.areThereKnownRadonConcentrations`}
          fieldWrapperClassName="pt-xl"
        />
      )}

      <Field
        validate={required}
        placeholder={HOME_DISCLOSURES_STRINGS.describe}
        component={InputFinalFormAdapter}
        name={`disclosures.types.${item.name}.comment`}
      />

      {item?.filesAllowed && (
        <Field
          name={`disclosures.types.${item.name}.files`}
          validate={item?.filesRequired ? validateMedia : undefined}
          component={NewMediaUploadAdapter}
          mediaLabel={MEDIA_LABEL.FILE_UPLOAD}
          disableWebcam
        />
      )}
    </Col>
  );
}
