import { US_STATES } from '@belong/common';

const getSignDescriptionTemplate = (licenser) => {
  return `Belong is licensed by the ${licenser}. Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`;
};

export const LEASE_SIGNING_FLOW_STRINGS = {
  //Landing step
  'landing.cover.title1': `In {days}, this could be <span>{your} {sectionName}.</span>`,
  'landing.cover.title2': `This could be <span>{your} {sectionName}.</span>`,
  'landing.cover.title-expired': 'This home is <span>no longer available.</span>',
  'landing.cover.title-renewal': 'Still in love? <span>Let’s make it official.</span>',
  'landing.employee.text': `Congratulations {firstName}, you are approved for this home! <span>It’s yours if you want it!</span>`,
  'landing.footer.text': `Holding your place for {time}`,
  'landing.fotter.text-expired': 'Lease expired',
  'landing.footer.cta': `Make this {my} home!`,
  'landing.footer.cta-renewal': `Ready To Renew!`,
  'landing.footer.cta-expired': 'View Other Listings',
  'landing.footer.cta-waiting-primary': "Awaiting {primaryName}'s Signature",

  //Sign step
  // TODO: Regions: Check it
  'Sign.tips.description': {
    [US_STATES.CA.code]: getSignDescriptionTemplate('California Department of Real Estate (DRE License #02082345)'),
    [US_STATES.TX.code]: getSignDescriptionTemplate('Texas Department of Real Estate (DRE License #9013886)'),
    [US_STATES.UT.code]: getSignDescriptionTemplate('Utah Department of Real Estate (DRE License #13538686-CN00)'),
    [US_STATES.WA.code]: getSignDescriptionTemplate('Washington State Department of Licensing (License #21028494)'),
    [US_STATES.FL.code]: getSignDescriptionTemplate('Florida Department of Licensing (License #CQ1065529)'),
    [US_STATES.NC.code]: getSignDescriptionTemplate('North Carolina Real Estate Commission (License #C38631)'),
    [US_STATES.GA.code]: getSignDescriptionTemplate('Georgia Real Estate Commission (License #81255)'),
    [US_STATES.SC.code]: getSignDescriptionTemplate('South Carolina Real Estate Commission (License #27097)'),
    'WA-notarize':
      'Leases longer than 12 months must be notarized in the state of Washington. We partner with Notarize to make this seamless. Every adult needs to sign in a timely manner to ensure you get the home.',
    [US_STATES.CO.code]: getSignDescriptionTemplate(
      'Colorado Department of Regulatory Agencies, Division of Real Estate (IC. 100107131)'
    ),
    [US_STATES.IL.code]: getSignDescriptionTemplate('Illinois Division of Real Estate (License #478.027701)'),
    [US_STATES.TN.code]: getSignDescriptionTemplate('Tennessee Real Estate Commission (License #3002945415)'),
    [US_STATES.NV.code]: getSignDescriptionTemplate('Nevada Real Estate Division (License PM.0168524.BKR)'),
    [US_STATES.PA.code]: getSignDescriptionTemplate('Pennsylvania Real Estate Commission (License RB070118)'),
    [US_STATES.AZ.code]: getSignDescriptionTemplate('Arizona Department of Real Estate (License CO717003000)'),
  },
  'sign.title': `We can't wait to welcome you to the <span>Belong community!</span>`,
  'sign.title-renewal': `We can’t wait to continue having you in the <span>Belong community!</span>`,
  'sign.subtitle': `We just need to get some legal stuff out of the <span>way first...</span>`,
  'sign.download': 'Download All',
  'sign.checkbox': `I agree to electronically sign this lease and <span>its addendums.</span>`,
  'sign.CTA': 'Sign',

  // Success step
  'success.employee.title.self-serve-signatures-pending-interviews-pending': `We got your signature! Once everyone in your party signs and we complete your interviews and references, we will finalize your lease and prepare for <span>your move-in!</span>`,
  'success.employee.title.self-serve-interview-pending': `We got your signature! Once we complete your interview and references, we will finalize your lease and prepare for <span>your move-in!</span>`,
  'success.employee.title.self-serve-signatures-pending': `We got your signature! Once everyone in your party signs, we will finalize your lease and prepare <span>your move-in!</span>`,
  'success.employee.title-renewal': `We're honored to continue to serve you!`,
  'success.employee.title': `Welcome to the Belong community! We can't wait to make you feel <span>at home.</span>`,

  'success.timeline.title': 'What’s Next?',
  shareableInfo: {
    bannerMessage: 'Share the celebration by referring a  homeowner. Earn $1,000.',
    shareableMessage:
      'I just signed a lease and I’m moving into a new home! Instead of being treated like a tenant, I get a dedicated Member Success Lead to take care of me and my homeowner. If you own a rental home, check them out! ',
  },
  shareableRenewal: {
    bannerTitle: 'We’re so excited you are staying in the Belong community.',
    bannerMessage: 'Share your experience and earn $1,000 for referring your favorite homeowner.',
    shareableMessage:
      'I love my home and have renewed my lease with Belong! If you’re interested in renting to qualified resident’s who will love your home as much as you do, check it out!',
  },
};
